import $ from 'jquery';
import FHRedactor from 'fh-redactor/build/fhRedactorIII';
import 'fslightbox';

// Core modules
import ScrollTo from '_core/js/modules/scroll-to';
import Notification from '_core/js/modules/notification';

// Modules
import ConfirmHandler from './modules/confirm-handler';
import PopupHandler from './modules/popup-handler';
import ObjectViewers from './modules/object-viewers';
import DropdownHandler from './modules/dropdown-handler';
import TableRowHandler from './modules/table-row-handler';
import MeasureContentHandler from './modules/measure-content-handler';
import ExpandContentHandler from './modules/expand-content-handler';
import CatalogSearch from './modules/catalog-search';
import Sticky from './modules/sticky';
import FHFileUpload from './modules/fh-file-upload';
import TutorialHandler from './modules/tutorial-handler';
import MeasureBookHandler from './modules/measure-book-handler';
import ToggleableAjaxHandler from './modules/toggleable-ajax-handler';
import CommentForm from './modules/community/post';
import Toggle from './modules/toggle';
import Chart from './modules/chart';
import Navigation from './modules/navigation';
import accordion from './modules/accordion';
import tabs from './modules/tabs';
import StringToHex from './modules/stringToHex';
import popupMenu from './modules/popupMenu';
import downloadForm from './modules/download-form';
import tooltip from './modules/tooltip';
import modal from './modules/modal';
import threadsList from './modules/threads-list';
import editComment from './modules/community/edit';
import createTopic from './modules/community/create-topic';
import communityFilter from './modules/community/community-filters';
import filterToggle from './modules/filter/toggle';
import filter from './modules/filter';
import Truncate from './modules/truncate';

$(() => {
    new ScrollTo();
    new Notification();
    new ConfirmHandler();
    new PopupHandler();
    new ObjectViewers();
    new DropdownHandler();
    new TableRowHandler();
    new MeasureContentHandler();
    new ExpandContentHandler();
    new CatalogSearch();
    new Sticky();
    new FHFileUpload();
    new TutorialHandler();
    new ToggleableAjaxHandler();
    new MeasureBookHandler();
    new CommentForm();
    new Toggle();
    new Chart();
    new Navigation();
    new StringToHex();
    new communityFilter();
    new Truncate();
    accordion.init();
    tabs.init();
    popupMenu.init();
    downloadForm.init();
    tooltip.init();
    modal.init();
    threadsList.init();
    editComment.init();
    createTopic.init();
    filterToggle.init();
    filter.filter.init();
    filter.sort.init();

    const showError = function ($element, message) {
        $element.show();
        $element.html(message);
    };

    $('[data-tool~=fh-redactor]').each((index, element) => {
        const $errorMessage = $(element)
            .closest('.redactor-container')
            .find('.js-upload-error');
        const maxFileSize = $(element)
            .closest('.redactor-container')
            .data('max-file-size');

        const maxFileSizeErrorMessage = `Het bestand kon niet worden geüpload. Het bestand mag maximaal ${maxFileSize}mb groot zijn.`;

        new FHRedactor(element, {
            buttons: ['bold', 'italic', 'lists', 'link', 'file', 'image'],
            callbacks: {
                upload: {
                    complete: function (response) {
                        $errorMessage.hide();
                        $errorMessage.html('');

                        if (response.error) {
                            showError($errorMessage, response.error);
                        }
                        if (!response.filelink) {
                            showError($errorMessage, maxFileSizeErrorMessage);
                        }
                    }
                },
                file: {
                    uploadError: function (response) {
                        let message = '';

                        if (typeof response.error === 'string') {
                            const found = response.error.match(
                                '^MimeType\\s+[^ ]+\\s+not supported'
                            );

                            message = found
                                ? 'Dit bestandstype wordt niet ondersteund.'
                                : response.error;
                        } else {
                            message = maxFileSizeErrorMessage;
                        }

                        $('.upload-redactor-box').append(`<span class="upload-error" style="color: red">${message}</span>`);
                    }
                },
                keydown: (event) => {
                    $errorMessage.hide();
                },
            }
        });
    });
});
